<template>
  <div>
    <label
      v-show="label"
      v-text="label"
      for="formFile"
      class="form-label"
    ></label>
    <input
      class="form-control"
      type="file"
      id="formFile"
      :accept="accept"
      @change="$emit('change', $event)"
      :class="{ 'is-invalid': validate.$error }"
    />
  </div>
</template>

<script>
export default {
  name: "FileInput",
  props: {
    label: String,
    accept: String,
    validate: {
      type: Object,
      default() {
        return {
          $error: false,
        };
      },
    },
  },
};
</script>
