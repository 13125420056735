<template>
  <div>
    <div class="d-flex justify-content-between">
      <h2>Импорт данных</h2>
      <div class="d-flex gap-3">
        <UIButton
          text="Скачать"
          leftIcon="bi-download"
          @click="downloadData"
          :disabled="
            !lodash.find(typeModel, (el) =>
              lodash.find(el.items, (it) => it.id === modelValue && it.template)
            )
          "
        />
        <UIButton text="Загрузить" leftIcon="bi-upload" @click="importData" />
      </div>
    </div>
    <div class="row align-items-start">
      <div class="col">
        <UISelect
          v-model="modelValue"
          :options="typeModel"
          :defaultValue="{ title: 'Необходимо выбрать' }"
          :withGroups="true"
          :validate="v$.modelValue"
          label="Тип документа"
          textKey="title"
          editable
        />
      </div>
      <div class="col">
        <UIFileInput
          label="Файл"
          :validate="v$.file"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          @change="fileChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { API_ExcelImport, API_ExcelModels } from "@/services/api";
import UISelect from "@/components/UI/CustomSelect";
import UIButton from "@/components/UI/Button";
import UIFileInput from "@/components/UI/FileInput";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import axios from "@/plugins/axios";

export default {
  name: "import-data",
  components: { UISelect, UIButton, UIFileInput },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      typeModel: [],
      modelList: [],
      groupId: null,
      modelValue: null,
      file: null,
    };
  },
  validations() {
    return {
      modelValue: { required },
      file: { required },
    };
  },
  methods: {
    fileChange(e) {
      this.file = e.target.files[0];
    },
    async importData() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      API_ExcelImport(this.modelValue, this.file)
        .then(() => {
          this.$notify({
            type: "success",
            duration: 1000,
            text: "Импорт выполнен",
          });
          this.$router.go(-1);
        })
        .catch(() => {
          this.$notify({
            type: "error",
            duration: 10000,
            text: "Неудалось импортировать файлы",
          });
        });
    },
    async downloadData() {
      window.open(
        `${axios.defaults.baseURL.slice(0, -3)}/templates/${
          this.modelValue
        }Template.xlsx`,
        "_blank"
      );
    },
  },
  mounted() {
    API_ExcelModels()
      .then((res) => {
        this.typeModel = res;
      })
      .catch(() => {
        this.$notify({
          type: "error",
          duration: 10000,
          text: "Неудалось загрузить типы файлов",
        });
      });
  },
  watch: {
    groupId: function () {
      this.modelList = this.typeModel[this.groupId].items;
    },
  },
};
</script>

<style scoped></style>
